"use client"

import type { TrackingType } from "@/modules/tracking/types"

import { createContext, useContext } from "react"

const TrackingLibContext = createContext<TrackingType>(null)

const useTracking = (): TrackingType => useContext(TrackingLibContext)

export { TrackingLibContext, useTracking }
