"use client"

import type { FunctionComponent } from "react"

import dynamic from "next/dynamic"
import { useState } from "react"

import { Icon } from "@/modules/headerFooterFO/Header/components/Icon/Icon"
import { useJobAdDetailTracking } from "@/modules/jobAdDetail/hooks/useJobAdDetailTracking"
import { useHref } from "@/modules/routing/hooks/useHref"

import styles from "./LoginButton.module.css"

const DynamicLoginModal = dynamic(
  () => import("@/modules/careerCenter/components/LoginModal/LoginModal").then(mod => mod.LoginModal),
  { ssr: false }
)

export type LoginButtonProps = {
  careerCenterName: string
  isWwwCareerCenter: boolean
  label: string
}

export const LoginButton: FunctionComponent<LoginButtonProps> = ({ careerCenterName, isWwwCareerCenter, label }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const { commonRefIdsProps } = useJobAdDetailTracking()
  const currentPageHref = useHref(true)

  const openLoginModal = (): void => {
    setIsLoginModalOpen(true)
  }

  const closeLoginModal = (): void => {
    setIsLoginModalOpen(false)
  }

  return (
    <>
      <button className={styles.main} type="button" onClick={openLoginModal}>
        <span className={styles.icon} aria-hidden>
          <Icon name="Profile" />
        </span>
        {label}
      </button>

      {isLoginModalOpen && (
        <DynamicLoginModal
          careerCenterName={careerCenterName}
          isWwwCareerCenter={isWwwCareerCenter}
          onClose={closeLoginModal}
          trackingRefIdsProps={commonRefIdsProps}
          urlRedirectionAfterLogin={currentPageHref}
        />
      )}
    </>
  )
}
