import type { QueryClientConfig, QueryObserverOptions } from "@tanstack/react-query"

import { QueryCache, QueryClient } from "@tanstack/react-query"

import { logger } from "@/modules/monitoring/logger"

const REACT_QUERY_DEFAULT_CACHE_TIME = 5 * 60 * 1000

const queriesDefaultOptions: QueryObserverOptions = {
  gcTime: REACT_QUERY_DEFAULT_CACHE_TIME,
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: REACT_QUERY_DEFAULT_CACHE_TIME,
}

export const createComponentQueryClient = (config: QueryClientConfig = {}): QueryClient =>
  new QueryClient({
    ...config,
    defaultOptions: {
      ...config?.defaultOptions,
      queries: {
        ...queriesDefaultOptions,
        ...config?.defaultOptions?.queries,
      },
    },
    queryCache: new QueryCache({
      onError: (error, { queryKey }) => {
        logger.error(`A client-side error occurred: ${error} with queryKey ${queryKey}`)
      },
    }),
  })
