"use client"

import type { ApplicationFlowStep } from "@/modules/jobAdDetail/components/ApplicationFlow/ApplicationFlow"
import type { ParamActionType } from "@/modules/jobAdDetail/hooks/useSearchParamActionType"
import type { TrackingContext } from "@/modules/jobAdDetail/utils/getTrackingContext"

import { createContext, useContext } from "react"

export type PageLayoutContextValue = {
  closeApplicationFlow: () => void
  closeLoginModal: () => void
  closeReportFlow: () => void
  openApplicationFlow: (step?: ApplicationFlowStep) => void
  openLoginFlow: (nextActionType: ParamActionType) => void
  openReportFlow: () => void
  printJobAd: () => void
  trackingContext: TrackingContext
}

export const PageLayoutContext = createContext<PageLayoutContextValue>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeApplicationFlow: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeLoginModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeReportFlow: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openApplicationFlow: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openLoginFlow: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openReportFlow: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  printJobAd: () => {},
  trackingContext: {} as TrackingContext,
})

export const usePageLayoutContext = (): PageLayoutContextValue => useContext(PageLayoutContext)
