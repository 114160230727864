"use client"

import { usePageLayoutContext } from "@/modules/jobAdDetail/components/PageLayout/PageLayoutContext"
import { useTrackGenericClickEvent } from "@/modules/tracking/useTrackGenericClickEvent"
import { useTracking } from "@/modules/tracking/useTracking"

export type JobAdDetailTrackingRoles =
  | "job-ad-detail-report-modal-close"
  | "job-ad-detail-report-modal-open"
  | "job-ad-detail-reported"
  | "job-ad-detail-print"
  | "job-ad-detail-dots-dropdown"
  | "job-ad-detail-description-more"
  | "job-ad-detail-description-less"
  | "job-ad-detail-edit-profile"
  | "job-ad-detail-show-resume"
  | "job-ad-detail-use-new-resume"
  | "job-ad-detail-make-as-temporary-resume"
  | "job-ad-detail-make-as-default-resume"
  | "job-ad-detail-cv-uploaded"
  | "job-ad-detail-cover-letter-uploaded"
  | "job-ad-detail-apply-modal-close"
  | "job-ad-detail-external-application-modal-display"
  | "job-ad-detail-congratulation-modal-display"
  | "job-ad-detail-apply-form-submit"
  | "shortlist_ad_apply_click"
  | "shortlist_ad_dismiss_click"
  | "job-ad-detail-company-images-gallery-click"
  | "job-ad-detail-company-video-play-click"
  | "job-ad-detail-company-carousel-left-button-click"
  | "job-ad-detail-company-carousel-right-button-click"
  | "job-ad-detail-company-carousel-left-button-fullscreen-click"
  | "job-ad-detail-company-carousel-right-button-fullscreen-click"
  | "job-ad-detail-company-carousel-close-fullscreen-click"

export type UseJobAdDetailTrackingReturn = {
  trackJobAdDetailSaveEvent: (isSaved: boolean) => void
  trackJobAdDetailDescriptionExpanded: (isExpanded: boolean) => void
  trackJobAdDetailDotsDropdownClick: (isOpen: boolean) => void
  trackJobAdDetailApplyClick: (applicationPlatform: string, visualContext: string) => void
  trackJobAdDetailGalleryMediaItem: (isVideo: boolean) => void
  trackEventClick: (role: JobAdDetailTrackingRoles, visualContext?: string) => void
  commonRefIdsProps: {
    job_ad_ids?: string[]
    company_ids?: string[]
    school_ids?: string[]
  }
}

export const useJobAdDetailTracking = (): UseJobAdDetailTrackingReturn => {
  const tracking = useTracking()
  const { trackingContext } = usePageLayoutContext()
  const eventClick = useTrackGenericClickEvent()

  const trackEventClick = (role: JobAdDetailTrackingRoles, visualContext?: string): void =>
    eventClick(role, {
      ref_ids: trackingContext.ref_ids,
      ...(visualContext && { visual_context: visualContext }),
    })

  /*
   * Emitted when an user click on apply button
   */
  const trackJobAdDetailApplyClick = (applicationPlatform: string, visualContext: string): void => {
    tracking?.track("job_ad_apply_click", {
      application_platform: applicationPlatform,
      company_id: trackingContext.company_id,
      company_name: trackingContext.company_name,
      context: "job_ad_page",
      contract_types: trackingContext.contract_types,
      country: trackingContext.country,
      course_ids: trackingContext.course_ids,
      creation_source: trackingContext.creation_source,
      genai_generated: trackingContext.genai_generated,
      job_ad_id: trackingContext.job_ad_id,
      locale: trackingContext.locale,
      position_category_name: trackingContext.position_category_name,
      school_id: trackingContext.school_id,
      service: "jobteaser",
      study_levels: trackingContext.study_levels,
      type_of_internal_application: trackingContext.type_of_internal_application,
      user_location: trackingContext.user_location,
      visual_context: visualContext,
      ...(tracking?.getRefererParams()?.referrer_params || {}),
    })
  }

  /*
   * Emitted when a jobad is saved or unsaved.
   */
  const trackJobAdDetailSaveEvent = (isSaved: boolean): void => {
    const trackingEvent = isSaved ? "job_ad_save" : "job_ad_dismiss_click"

    tracking?.track(trackingEvent, {
      company_id: trackingContext.company_id,
      context: "job_ad_page",
      job_ad_id: trackingContext.job_ad_id,
      service: "job_ads_fo",
      trackee_locale: trackingContext.trackee_locale,
      trackee_school_id: trackingContext.trackee_school_id,
      ...(tracking?.getRefererParams()?.referrer_params || {}),
    })
  }

  /*
   * Emitted when an user click on the read more or the read less of the job ad description.
   */
  const trackJobAdDetailDescriptionExpanded = (isExpanded: boolean): void => {
    const role = isExpanded ? "job-ad-detail-description-more" : "job-ad-detail-description-less"
    trackEventClick(role)
  }

  /*
   * Emitted when an user open 3 dots dropdown at the bottom of the job ad.
   */
  const trackJobAdDetailDotsDropdownClick = (isOpen: boolean): void => {
    const role = "job-ad-detail-dots-dropdown"

    if (!isOpen) {
      return
    }

    trackEventClick(role)
  }

  /*
   * Emitted when an user click on Company Gallery media item (image or video)
   */
  const trackJobAdDetailGalleryMediaItem = (isVideo: boolean): void => {
    const role = isVideo ? "job-ad-detail-company-video-play-click" : "job-ad-detail-company-images-gallery-click"

    trackEventClick(role)
  }

  return {
    commonRefIdsProps: trackingContext.ref_ids,
    trackEventClick,
    trackJobAdDetailApplyClick,
    trackJobAdDetailDescriptionExpanded,
    trackJobAdDetailDotsDropdownClick,
    trackJobAdDetailGalleryMediaItem,
    trackJobAdDetailSaveEvent,
  }
}
